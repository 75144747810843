export class SessionManager {
  clearUserSession () {
    localStorage.removeItem('token')
    // localStorage.removeItem('deviceId')
    // localStorage.removeItem('card')
    sessionStorage.removeItem('isLoggedIn')
    sessionStorage.removeItem('uuid')
    localStorage.removeItem('user')
    sessionStorage.removeItem('logaToken')
    localStorage.removeItem('cardMenu')
    // sessionStorage.removeItem('card')
  }

  resetUserState () {
    sessionStorage.removeItem('uuid')
    localStorage.removeItem('user')
    sessionStorage.removeItem('logaToken')
    localStorage.removeItem('cardMenu')
  }

  resetCardState () {
    localStorage.removeItem('user')
    localStorage.removeItem('cardMenu')
  }
}
