import Vue from 'vue'
import Vuex from 'vuex'
import './registerServiceWorker'
import App from './App.vue'
import router from './router'
import { Api } from './assets/js/api.js'
import { Utility } from './assets/js/utility.js'
import { SessionManager } from './assets/js/session-manager.js'
import i18n from './i18n'
import VueGtag from 'vue-gtag'
import vuetify from './plugins/vuetify'
import VueScrollTo from 'vue-scrollto'
import VConsole from 'vconsole'
import VueCookies from 'vue-cookies'
import LongPress from './plugins/vue-directive-long-press'

window.isVueLoaded = true

// eslint-disable-next-line no-unused-vars
const vConsole = (process.env.VUE_APP_TYPE !== 'production') ? new VConsole() : null
Vue.use(Vuex)
Vue.use(VueScrollTo)
Vue.use(VueCookies, { expire: '1d' })
Vue.directive('long-press', LongPress)

const urlParams = new URLSearchParams(window.location.search)
const alpha = urlParams.get('alpha')
if (alpha === '1') {
  sessionStorage.setItem('useAlphaApi', true)
} else {
  sessionStorage.removeItem('useAlphaApi')
}

const gtagOptions = {
  config: { id: 'G-7LNCML8FHJ' },
  params: {
    send_page_view: false
  },
  enabled: process.env.VUE_APP_TYPE === 'production',
  // enabled: true,
  pageTrackerTemplate (to) {
    const card = sessionStorage.getItem('card') ? JSON.parse(sessionStorage.getItem('card')) : {}
    return {
      page_title: to.name,
      page_path: to.path,
      card_id: card && card.id ? card.id : null,
      card_name: card && card.name ? card.name : ''
    }
  }
}
Vue.use(VueGtag, gtagOptions, router)

Vue.config.productionTip = false

const api = new Api()
const utility = new Utility()
const sessionManager = new SessionManager()

// Vuex stores
const loadingSpinner = new Vuex.Store({
  state: {
    isLoading: false
  },
  mutations: {
    show (state) { state.isLoading = true },
    hide (state) { state.isLoading = false }
  }
})
const dialogWarning = new Vuex.Store({
  state: {
    showDialog: false,
    message: null,
    detail: null,
    onClose: () => null
  },
  mutations: {
    show (state, payload) {
      state.showDialog = true
      state.message = payload.message
      state.detail = payload.detail
      if (payload.onClose) state.onClose = payload.onClose
    },
    hide (state) {
      state.showDialog = false
      state.onClose = () => null
    }
  }
})
const snackbar = new Vuex.Store({
  state: {
    showSnack: false,
    message: null
  },
  mutations: {
    show (state, payload) {
      state.showSnack = true
      state.message = payload.message
    },
    hide (state) {
      state.showSnack = false
    }
  }
})
const routerViewKey = new Vuex.Store({
  state: { value: 0 },
  mutations: {
    increment (state) { state.value = state.value + 1 }
  }
})

Vue.mixin({
  data () {
    return {
      api,
      utility,
      sessionManager,
      loadingSpinner,
      dialogWarning,
      snackbar,
      routerViewKey
    }
  }
})

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
