<template>
  <v-dialog data-cy="dialog-warning" v-model="dialogState" width="378" @clicked:outside="close">
    <v-card>
        <v-card-title data-cy="dialog-warning-title" class="dialog-title primary text-lg">
          {{ $t('dialog.warning') }}
        </v-card-title>

        <div data-cy="dialog-warning-content" class="dialog-content">
          <div v-if="message" class="message text-lg" v-html="message">
          </div>
          <div v-if="detail" class="detail text--secondary text-md" v-html="detail">
          </div>
        </div>

        <v-card-actions class="dialog-actions">
          <v-btn
            data-cy="dialog-warning-ok"
            depressed
            color="primary"
            @click="close"
            class="btn-modal-ok"
          >
            {{ $t('app.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogWarning',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
    detail: {
      type: String,
      default: null
    }
  },
  computed: {
    dialogState: {
      get () {
        return this.showDialog
      },
      set (val) {
        this.close()
      }
    }
  },
  methods: {
    close (e) {
      this.$emit('on-close')
      this.dialogWarning.commit('hide')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/dialog-warning.scss';
</style>
