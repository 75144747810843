export class Utility {
  getBrowserName () {
    const ua = navigator.userAgent
    let tem
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return { name: 'IE', version: (tem[1] || '') }
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) {
        return { name: 'Opera', version: tem[1] }
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]) }
    // return {
    //   name: M[0],
    //   version: M[1]
    // }
    return M[0]
  }

  isMobileNativeApp () {
    return this.isAndroidNativeApp() || this.isIosNativeApp()
  }

  isAndroidNativeApp () {
    return typeof (Android) !== 'undefined'
  }

  isIosNativeApp () {
    return window.webkit && window.webkit.messageHandlers
  }

  getImgSrc (fullname, isStatic = false) {
    if (fullname) {
      try {
        return isStatic ? `${process.env.VUE_APP_BASE_URL}static/img/${fullname}` : require(`@/assets/img/${fullname}`)
      } catch (ex) {
        return ''
      }
    }
    return ''
  }

  getImgSrcSet (name, type = 'png', isStatic = false) {
    if (name && type) {
      const x = `${name}@1x.${type}`
      const xx = `${name}@2x.${type}`
      const xxx = `${name}@3x.${type}`
      const xxxx = `${name}@4x.${type}`
      const srcSet = `${this.getImgSrc(x, isStatic)} 1x, ${this.getImgSrc(xx, isStatic)} 2x, ${this.getImgSrc(xxx, isStatic)} 3x, ${this.getImgSrc(xxxx, isStatic)} 4x`
      return srcSet === ' 1x,  2x,  3x,  4x' ? '' : srcSet
    }
    return ''
  }

  getCardImgSrc (cardId, levelId) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/crop?level=${levelId}`
  }

  getCardFullImgSrc (cardId, levelId) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/full?level=${levelId}`
  }

  getCoverImgSrc (cardId, levelId) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/cover?level=${levelId}`
  }

  getCardIconImgSrc (cardId) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/icon`
  }

  getCardIcon3xImgSrc (cardId) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/icon`
  }

  getCardBranchImgSrc (cardId, branchId) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/branch${branchId}`
  }

  getCardMarkerImgSrc (cardId, zoom = 1) {
    return `${this.getApiUrl()}client/getcardimg/${cardId}/marker/${zoom}`
  }

  isCardInMyanmar (cardId) {
    const id = parseInt(cardId)
    return (process.env.VUE_APP_TYPE === 'test' && id === 188) || (process.env.VUE_APP_TYPE === 'production' && id === 5092)
  }

  capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  formatPhone (phone) {
    return (phone && phone.substring(0, 3) === '+66') ? '0' + phone.substring(3) : phone
  }

  callTelephone (e) {
    if (this.isAndroidNativeApp()) {
      e.preventDefault()
      const params = new URLSearchParams()
      params.append('src', e.target.href)
      window.location = `/redirect?${params.toString()}`
    }
  }

  isUrlInDomain (url) {
    try {
      const domain = (new URL(url))
      return domain && (domain.hostname.endsWith('.loga.app') || domain.hostname.endsWith('.longdo.com'))
    } catch (error) {
      return false
    }
  }

  isUrlInJonesSaladDomain (url) {
    try {
      const domain = (new URL(url))
      return domain && domain.hostname.endsWith('jonessalad.com')
    } catch (error) {
      return false
    }
  }

  getAlertMessageFromUrl (url) {
    if (url.startsWith('logaalert://')) {
      return url.substring(12)
    }
    return null
  }

  getMapSnippet ({ locale, cardId, ooiId, width, height }) {
    return 'https://mmmap15.longdo.com/mmmap/snippet/?' +
    `locale=${locale}&` +
    'zoom=15&' +
    `ooi=${ooiId}&` +
    `width=${width}&` +
    `height=${height}&` +
    `pinmark=icon:${this.getCardMarkerImgSrc(cardId)};offsetX:center;offsetY:bottom;&` +
    'HD=1'
  }

  getApiUrl () {
    return sessionStorage.getItem('useAlphaApi') ? process.env.VUE_APP_API_ALPHA_URL : process.env.VUE_APP_API_URL
  }

  getScannedCode (code) {
    if (code.startsWith('http://')) code = code.replace(/^http:\/\//, '')
    else if (code.startsWith('https://')) code = code.replace(/^https:\/\//, '')
    return code
  }

  getCurrencySymbol (currency) {
    switch (currency) {
      case 'THB': return '฿'
      case 'USD': return '$'
      default: return currency
    }
  }

  // Shop-related functions
  isShopOpen (branch) {
    if (!branch) return true
    const currentDay = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok', weekday: 'short' }).toUpperCase()
    const openingHours = branch.opening_hours ? branch.opening_hours[currentDay] : null
    if (!openingHours) return false
    const timeZone = 'Asia/Bangkok'
    const currentTime = new Date()
    const openTime = this.getDateFromTimeString(openingHours[0], timeZone)
    const closeTime = this.getDateFromTimeString(openingHours[1], timeZone)
    return (branch.shop_open !== 'N') &&
      (branch.closed !== 'Y') &&
      (currentTime > openTime && currentTime < closeTime)
  }

  isShopAlmostClosed (branch) {
    if (!branch) return false
    const currentDay = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok', weekday: 'short' }).toUpperCase()
    const openingHours = branch.opening_hours ? branch.opening_hours[currentDay] : null
    if (!openingHours) return false
    const timeZone = 'Asia/Bangkok'
    const currentTime = new Date()
    const currentTimeplus = new Date(currentTime.getTime() + 15 * 60000) // plus 15 minutes
    const openTime = this.getDateFromTimeString(openingHours[0], timeZone)
    const closeTime = this.getDateFromTimeString(openingHours[1], timeZone)
    return (branch.shop_open !== 'N') &&
      (branch.closed !== 'Y') &&
      (currentTime > openTime && currentTime < closeTime && (currentTimeplus > closeTime))
  }

  getShopItemDescription (item) {
    if (!item) return ''
    const itemText = []
    if (item.option_name) itemText.push(item.option_name)
    if (item.topping_groups instanceof Object) itemText.push(Object.values(item.topping_groups).map(x => x.toppings.map(y => y.name).join(', ')).join(', '))
    return itemText.join(', ')
  }

  // Date/Time-related functions
  getNumericDateStringFromEpoch (timestamp, locale) {
    locale = locale || 'en'
    const d = new Date(timestamp * 1000)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear() + (locale === 'th' ? 543 : 0)
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('/')
  }

  getLocaleStringFromLocale (locale) {
    let localeString = null
    switch (locale) {
      case 'th':
        localeString = 'th-TH'
        break
      case 'jp':
        localeString = 'ja-JP'
        break
      default:
        localeString = 'en-US'
        break
    }
    return localeString
  }

  getLongTimeStringWithSecondsFromEpoch (timestamp, locale) {
    locale = locale || 'en'
    const d = new Date(timestamp * 1000)
    let hour = d.getHours()
    let minute = d.getMinutes()
    let second = d.getSeconds()
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    if (second < 10) second = '0' + second
    return `${hour}:${minute}:${second}, ${d.toLocaleString(this.getLocaleStringFromLocale(locale), { day: 'numeric', month: 'long', year: 'numeric' })}`
  }

  getLongTimeStringFromEpoch (timestamp, locale, longMonth = true) {
    locale = locale || 'en'
    const d = new Date(timestamp * 1000)
    let hour = d.getHours()
    let minute = d.getMinutes()
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    return `${d.toLocaleString(this.getLocaleStringFromLocale(locale), { month: longMonth ? 'long' : 'short', day: 'numeric', year: 'numeric' })}${longMonth ? '' : ','} ${hour}:${minute}`
  }

  getTimeStringFromEpoch (timestamp, locale, showDate = true) {
    locale = locale || 'en'
    const now = new Date()
    const d = new Date(timestamp * 1000)
    let hour = d.getHours()
    let minute = d.getMinutes()
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    if (!showDate || (now.toDateString() === d.toDateString())) return `${hour}:${minute}`
    else {
      return `${d.toLocaleString(this.getLocaleStringFromLocale(locale), { month: 'short', day: 'numeric', year: 'numeric' })}`
    }
  }

  getShortDateStringFromEpoch (timestamp, locale, showFullYear = false, useThaiTimezone = false) {
    locale = locale || 'en'
    const now = new Date()
    const d = new Date(timestamp * 1000)

    const options = { month: 'short', day: 'numeric' }
    if (useThaiTimezone) options.timeZone = 'Asia/Bangkok'
    if (showFullYear || now.getFullYear() !== d.getFullYear()) options.year = 'numeric'
    return `${d.toLocaleString(this.getLocaleStringFromLocale(locale), options)}`
  }

  getShortDateStringFromISOString (dateString, locale, showFullYear = false, useThaiTimezone = false) {
    locale = locale || 'en'
    const now = new Date()
    const d = new Date(dateString)

    const options = { month: 'short', day: 'numeric' }
    if (useThaiTimezone) options.timeZone = 'Asia/Bangkok'
    if (showFullYear || now.getFullYear() !== d.getFullYear()) options.year = 'numeric'
    return `${d.toLocaleString(this.getLocaleStringFromLocale(locale), options)}`
  }

  getShortTimeStringFromEpoch (timestamp, useThaiTimezone) {
    const d = new Date(timestamp * 1000)
    let hour = useThaiTimezone ? ((d.getUTCHours() + 7) % 24) : d.getHours()
    let minute = useThaiTimezone ? d.getUTCMinutes() : d.getMinutes()
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    return `${hour}:${minute}`
  }

  getDateFromTimeString (timeString, timeZone) {
    const [hours, minutes] = timeString.split(':').map(Number)
    const currentDate = new Date()
    const dateObjectWithTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes
    )
    const formattedDate = dateObjectWithTime.toLocaleString('en-US', { timeZone: timeZone })
    return new Date(formattedDate)
  }

  getBeginningTimeOfDay (day, since) {
    const d = new Date(since * 1000)
    d.setDate(d.getDate() + day + 1) // + 1 because we round up
    d.setHours(0, 0, 0, 0)
    return parseInt(d / 1000) // return in seconds
  }

  getDurationTimeString (duration, locale) {
    const days = parseInt(duration / 86400)
    const hours = parseInt((duration % 86400) / 3600)
    const minutes = parseInt((duration % 3600) / 60)
    const seconds = parseInt(duration % 60)
    const ar = []
    if (days) ar.push(`${days} ${locale === 'th' ? 'วัน' : ('day' + (days > 1 ? 's' : ''))}`)
    if (hours) ar.push(`${hours} ${locale === 'th' ? 'ชม.' : 'hr'}`)
    if (minutes) ar.push(`${minutes} ${locale === 'th' ? 'นาที' : 'min'}`)
    if (seconds) ar.push(`${seconds} ${locale === 'th' ? 'วินาที' : 'sec'}`)
    return ar.join(' ')
  }

  getPostCreateTimeString (timestamp, locale) {
    const currentTime = parseInt(new Date().getTime() / 1000)
    const duration = currentTime - timestamp
    const days = parseInt(duration / 86400)
    const hours = parseInt((duration % 86400) / 3600)
    const minutes = parseInt((duration % 3600) / 60)
    const seconds = parseInt(duration % 60)
    const ar = []
    if (days > 7) return this.getTimeStringFromEpoch(timestamp)
    if (days) ar.push(`${days} ${locale === 'th' ? 'วัน' : 'd'}`)
    if (hours) ar.push(`${hours} ${locale === 'th' ? 'ชม.' : 'h'}`)
    if (minutes) ar.push(`${minutes} ${locale === 'th' ? 'นาที' : 'm'}`)
    if (seconds) ar.push('Just now')
    return ar.length ? ar[0] : this.getTimeStringFromEpoch(timestamp)
  }

  getDistanceFromLatLonInKm (lat1, lon1, lat2, lon2) {
    const R = 6371 // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1)
    const dLon = this.deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return d
  }

  deg2rad (deg) {
    return deg * (Math.PI / 180)
  }

  getPluralSuffix (amount, locale) {
    return locale === 'en' && parseInt(amount) !== 1 ? 's' : ''
  }

  toFixedDecimal (x, alwaysDecimal = false, digit = 2) {
    // function adds commas in undesirable places if there are more than 3 digits after the decimal point
    // src: https://stackoverflow.com/questions/2901102
    return x.toFixed((!alwaysDecimal && Number.isInteger(x)) ? 0 : digit).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  isElementPartiallyInViewport (el) {
    const rect = el.getBoundingClientRect()
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth)
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0)
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0)
    return (vertInView && horInView)
  }

  takeScreenShotForNative (message) {
    if (typeof (Android) !== 'undefined') { // Android
      // eslint-disable-next-line no-undef
      Android.takeScreenshot(message)
    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.takeScreenshot) { // iOS
      window.webkit.messageHandlers.takeScreenshot.postMessage({ description: message })
    }
  }
}
