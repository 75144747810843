import Vue from 'vue'
import VueRouter from 'vue-router'
import Card from '@/views/Card.vue'
import Verify from '@/views/card/Verify.vue'

Vue.use(VueRouter)

let routes = [
  {
    path: '/loga-app-redirect/:cardId',
    name: 'LogaAppRedirect',
    component: () => import(/* webpackChunkName: 'LogaAppRedirect' */ '@/views/LogaAppRedirect.vue')
  },
  {
    path: '/login/line/:cardId',
    name: 'LoginLine',
    component: () => import(/* webpackChunkName: 'LoginLine' */ '@/views/login/LoginLine.vue')
  },
  {
    path: '/login',
    name: 'LoginLoga',
    component: () => import(/* webpackChunkName: 'LoginLoga' */ '@/views/login/LoginLoga.vue')
  },
  {
    path: '/card/:cardId/gb-redirect',
    name: 'GbRedirect',
    component: () => import(/* webpackChunkName: 'GbRedirect' */ '@/views/GbRedirect.vue')
  },
  {
    path: '/card/:cardId/join',
    name: 'Join',
    component: () => import(/* webpackChunkName: 'Join' */ '@/views/card/Join.vue')
  },
  {
    path: '/card/:cardId/verify',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/card/:cardId/activity',
    name: 'Activity',
    component: () => import(/* webpackChunkName: 'Activity' */ '@/views/card/Activity.vue')
  },
  {
    path: '/card/:cardId/expiring-points',
    name: 'ExpiringPoints',
    component: () => import(/* webpackChunkName: 'ExpiringPoints' */ '@/views/card/ExpiringPoints.vue')
  },
  {
    path: '/card/:cardId/level-history',
    name: 'LevelHistory',
    component: () => import(/* webpackChunkName: 'LevelHistory' */ '@/views/card/LevelHistory.vue')
  },
  {
    path: '/card/:cardId/coupon',
    name: 'Coupon',
    component: () => import(/* webpackChunkName: 'Coupon' */ '@/views/card/Coupon.vue')
  },
  {
    path: '/card/:cardId/coupon-detail/:couponId',
    name: 'CouponDetail',
    component: () => import(/* webpackChunkName: 'Coupon' */ '@/views/card/CouponDetail.vue')
  },
  {
    path: '/card/:cardId/reward',
    name: 'Reward',
    component: () => import(/* webpackChunkName: 'Reward' */ '@/views/card/Reward.vue')
  },
  {
    path: '/card/:cardId/profile-edit',
    name: 'ProfileEdit',
    component: () => import(/* webpackChunkName: 'ProfileEdit' */ '@/views/card/ProfileEdit.vue')
  },
  {
    path: '/card/:cardId/mobile-edit',
    name: 'MobileEdit',
    component: () => import(/* webpackChunkName: 'ProfileEdit' */ '@/views/card/MobileEdit.vue')
  },
  {
    path: '/card/:cardId/verify-mobile',
    name: 'VerifyPhone',
    component: Verify
  },
  {
    path: '/card/:cardId/external',
    name: 'ExternalPage',
    component: () => import(/* webpackChunkName: 'ExternalPage' */ '@/views/ExternalPage.vue')
  },
  {
    path: '/card/:cardId',
    name: 'Card',
    component: Card
  },
  // Credit
  {
    path: '/card/:cardId/credit',
    name: 'Credit',
    component: () => import(/* webpackChunkName: 'Credit' */ '@/views/credit/Credit.vue')
  },
  {
    path: '/card/:cardId/credit-detail/:creditId',
    name: 'CreditDetail',
    component: () => import(/* webpackChunkName: 'Credit' */ '@/views/credit/CreditDetail.vue')
  },
  {
    path: '/card/:cardId/credit-topup/:creditId',
    name: 'CreditTopup',
    component: () => import(/* webpackChunkName: 'Credit' */ '@/views/credit/CreditTopup.vue')
  },
  {
    path: '/card/:cardId/credit-credit',
    name: 'CreditCredit',
    component: () => import(/* webpackChunkName: 'Credit' */ '@/views/credit/CreditCredit.vue')
  },
  // Shop
  {
    path: '/card/:cardId/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/Shop')
  },
  {
    path: '/card/:cardId/shop/branch',
    name: 'ShopBranch',
    component: () => import(/* webpackChunkName: 'ShopBranch' */ '@/views/shop/ShopBranch')
  },
  {
    path: '/card/:cardId/shop/category/:category/:title',
    name: 'ShopCategory',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopCategory')
  },
  {
    path: '/card/:cardId/shop/item/:productId',
    name: 'ShopItem',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopItem')
  },
  {
    path: '/card/:cardId/shop/item/:productId/edit',
    name: 'ShopItemEdit',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopItem')
  },
  {
    path: '/card/:cardId/shop/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/Cart')
  },
  {
    path: '/card/:cardId/shop/review-order',
    name: 'ReviewOrder',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ReviewOrder')
  },
  {
    path: '/card/:cardId/shop/order-detail/:orderId',
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/OrderDetail')
  },
  {
    path: '/card/:cardId/shop/my-order',
    name: 'MyOrder',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/MyOrder')
  },
  {
    path: '/card/:cardId/shop/credit',
    name: 'ShopCredit',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopCredit')
  },
  {
    path: '/card/:cardId/shop/approve-order',
    name: 'PaymentLanding',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/PaymentLanding')
  },
  // Booking
  {
    path: '/card/:cardId/booking',
    name: 'Booking',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/Booking')
  },
  {
    path: '/card/:cardId/booking/activity/:activityId',
    name: 'BookingActivity',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingActivity')
  },
  {
    path: '/card/:cardId/booking/summary',
    name: 'BookingSummary',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingSummary')
  },
  {
    path: '/card/:cardId/booking/credit',
    name: 'BookingCredit',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingCredit')
  },
  {
    path: '/card/:cardId/booking/detail/:appointmentId',
    name: 'BookingDetail',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingDetail')
  },
  {
    path: '/card/:cardId/booking/my-booking',
    name: 'MyBooking',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/MyBooking')
  },
  // Info
  {
    path: '/card/:cardId/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: 'Info' */ '@/views/info/Info')
  },
  {
    path: '/card/:cardId/info/branch',
    name: 'InfoBranch',
    component: () => import(/* webpackChunkName: 'Info' */ '@/views/info/InfoBranch')
  },
  {
    path: '/card/:cardId/info/branch-map',
    name: 'InfoBranchMap',
    component: () => import(/* webpackChunkName: 'Info' */ '@/views/info/InfoBranchMap')
  },
  // Address
  {
    path: '/card/:cardId/address/edit/:addressId',
    name: 'AddressEdit',
    component: () => import(/* webpackChunkName: 'AddressEdit' */ '@/views/address/AddressEdit')
  },
  {
    path: '/card/:cardId/address/edit',
    name: 'AddressCreate',
    component: () => import(/* webpackChunkName: 'AddressEdit' */ '@/views/address/AddressEdit')
  },
  {
    path: '/card/:cardId/address/map',
    name: 'AddressMap',
    component: () => import(/* webpackChunkName: 'AddressEdit' */ '@/views/address/AddressMap')
  },
  {
    path: '/card/:cardId/address/map/:addressId',
    name: 'AddressMapEdit',
    component: () => import(/* webpackChunkName: 'AddressEdit' */ '@/views/address/AddressMap')
  },
  {
    path: '/card/:cardId/address/select',
    name: 'AddressSelect',
    component: () => import(/* webpackChunkName: 'AddressEdit' */ '@/views/address/AddressSelect')
  },
  // Updates
  {
    path: '/card/:cardId/updates',
    name: 'Updates',
    component: () => import(/* webpackChunkName: 'Updates' */ '@/views/updates/Updates.vue')
  },
  {
    path: '/card/:cardId/post/:postId',
    name: 'PostDetail',
    component: () => import(/* webpackChunkName: 'Updates' */ '@/views/updates/PostDetail.vue')
  },
  // Package
  {
    path: '/card/:cardId/package/current',
    name: 'PackageCurrent',
    component: () => import(/* webpackChunkName: 'PackageCurrent' */ '@/views/package/PackageCurrent.vue')
  },
  {
    path: '/card/:cardId/package/history',
    name: 'PackageHistory',
    component: () => import(/* webpackChunkName: 'PackageHistory' */ '@/views/package/PackageHistory.vue')
  },
  {
    path: '/card/:cardId/package/select',
    name: 'PackageSelect',
    component: () => import(/* webpackChunkName: 'PackageSelect' */ '@/views/package/PackageSelect.vue')
  },
  {
    path: '/card/:cardId/package/detail/:packageId',
    name: 'PackageConfirm',
    component: () => import(/* webpackChunkName: 'PackageConfirm' */ '@/views/package/PackageConfirm.vue')
  },
  {
    path: '/card/:cardId/package/credit',
    name: 'PackageCredit',
    component: () => import(/* webpackChunkName: 'PackageCredit' */ '@/views/package/PackageCredit.vue')
  },
  {
    path: '/card/:cardId/package/credit-change',
    name: 'PackageCreditChange',
    component: () => import(/* webpackChunkName: 'PackageCreditChange' */ '@/views/package/PackageCreditChange.vue')
  },
  // Custom
  // // JID Wallet
  {
    path: '/card/:cardId/jid/wallet',
    name: 'JIDWallet',
    component: () => import(/* webpackChunkName: 'JID' */ '@/custom/kuro/views/JIDWallet.vue')
  },
  {
    path: '/card/:cardId/jid/info',
    name: 'JIDInfo',
    component: () => import(/* webpackChunkName: 'JID' */ '@/custom/kuro/views/JIDInfo.vue')
  },
  {
    path: '/card/:cardId/jid/verify',
    name: 'JIDVerify',
    component: () => import(/* webpackChunkName: 'JID' */ '@/custom/kuro/views/JIDVerify.vue')
  }
  // {
  //   path: '/card/:cardId/',
  //   name: 'Card',
  //   component: Card,
  //   children: [
  //     {
  //       path: 'activity',
  //       name: 'Activity',
  //       component: Activity
  //     }
  //   ]
  // }
]

const alternateRoutes = [
  // Shop
  {
    path: '/shop/:cardId',
    name: 'ShopAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/Shop')
  },
  {
    path: '/shop/:cardId/category/:category/:title',
    name: 'ShopCategoryAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopCategory')
  },
  {
    path: '/shop/:cardId/item/:productId',
    name: 'ShopItemAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopItem')
  },
  {
    path: '/shop/:cardId/cart',
    name: 'CartAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/Cart')
  },
  {
    path: '/shop/:cardId/review-order',
    name: 'ReviewOrderAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ReviewOrder')
  },
  {
    path: '/shop/:cardId/order-detail/:orderId',
    name: 'OrderDetailAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/OrderDetail')
  },
  {
    path: '/shop/:cardId/my-order',
    name: 'MyOrderAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/MyOrder')
  },
  {
    path: '/shop/:cardId/credit',
    name: 'ShopCreditAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/ShopCredit')
  },
  {
    path: '/shop/:cardId/approve-order',
    name: 'PaymentLandingAlt',
    component: () => import(/* webpackChunkName: 'Shop' */ '@/views/shop/PaymentLanding')
  },
  // Booking
  {
    path: '/booking/:cardId',
    name: 'BookingAlt',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/Booking')
  },
  {
    path: '/booking/:cardId/activity/:activityId',
    name: 'BookingActivityAlt',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingActivity')
  },
  {
    path: '/booking/:cardId/summary',
    name: 'BookingSummaryAlt',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingSummary')
  },
  {
    path: '/booking/:cardId/credit',
    name: 'BookingCreditAlt',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingCredit')
  },
  {
    path: '/booking/:cardId/detail/:appointmentId',
    name: 'BookingDetailAlt',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/BookingDetail')
  },
  {
    path: '/booking/:cardId/my-booking',
    name: 'MyBookingAlt',
    component: () => import(/* webpackChunkName: 'Booking' */ '@/views/booking/MyBooking')
  }
]

if (process.env.NODE_ENV !== 'development') routes = [...routes, ...alternateRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  // make scroll position persistent in Shop, ShopCategory, Coupon page
  scrollBehavior (to, from, savedPosition) {
    const ar = {
      Shop: [
        { name: 'shopScrollTop', selector: '#tab-content-scroll .scroll-container' },
        { name: 'shopSearchScrollTop', selector: '#shop-search-content .scroll-container' }
      ],
      ShopCategory: [
        { name: 'shopCategoryScrollTop', selector: '#shop-category-content .scroll-container' },
        { name: 'shopCategorySearchScrollTop', selector: '#shop-search-content .scroll-container' }
      ],
      Coupon: [
        { name: 'couponScrollTop', selector: '.coupon-content' }
      ]
    }
    for (const i in Object.keys(ar)) {
      const key = Object.keys(ar)[i]
      if (to.name === key) {
        for (const j in ar[to.name]) {
          const item = ar[to.name][j]
          if (sessionStorage.getItem(item.name)) {
            const e = document.querySelector(item.selector)
            if (e) e.scrollTop = sessionStorage.getItem(item.name)
          }
        }
      }
    }
    return { x: 0, y: 0 }
  }
})

export default router
