<template>
  <div id="loga-footer" v-long-press="1000" @long-press-start="toggleVConsoleButton">
    <div>
      <span class="text--secondary">Powered by </span>
      <img :srcSet="utility.getImgSrcSet('logo_loga' + ($vuetify && $vuetify.theme && $vuetify.theme.dark ? '_dark' : ''), 'png', true)" />
    </div>
    <div class="text--secondary text-sm mt-1">Version: {{ VUE_APP_VERSION }}</div>
  </div>
</template>

<script>
export default {
  name: 'LogaFooter',
  data () {
    return {
      VUE_APP_VERSION: process.env.VUE_APP_VERSION
    }
  },
  methods: {
    toggleVConsoleButton () {
      const vcSwitch = document.querySelector('.vc-switch')
      if (vcSwitch) {
        if (vcSwitch.classList.contains('d-block')) vcSwitch.classList.remove('d-block')
        else vcSwitch.classList.add('d-block')
      }
    }
  }
}
</script>

<style scoped lang="scss">
#loga-footer {
  text-align: center;
  padding: 24px 0 16px;
  img {
    vertical-align: middle;
  }
}
</style>
