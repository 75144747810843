<template>
  <div class="cookie-banner">
    <div>
      <p>
        เว็บไซต์นี้ใช้คุกกี้เพื่อปรับปรุงประสบการณ์ของผู้ใช้ให้ดียิ่งขึ้น ดู <a :href="'https://loga.app/privacy'" class="loga-secondary--text" @click="openLink">นโยบายความเป็นส่วนตัว</a>
      </p>
      <p>
        This site uses cookies to give you the best experience on our site. See <a :href="'https://loga.app/privacy'" class="loga-secondary--text" @click.prevent.stop="openLink">our Privacy Policy</a>.
      </p>
    </div>
    <div class="text-right">
      <v-btn @click="accept" color="loga-secondary" style="color: white;">ยอมรับ / Accept</v-btn>
    </div>
  </div>
</template>

<script>
import liff from '@line/liff'

export default {
  name: 'CookieBanner',
  data () {
    return {
    }
  },
  methods: {
    openLink (e) {
      liff.openWindow({
        url: e.target.href
      })
    },
    accept () {
      this.$emit('on-accept')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/cookie-banner.scss';
</style>
