<template>
  <v-app>
    <keep-alive include="Booking,MyBooking,Shop,MyOrder,ReviewOrder,Coupon">
      <router-view v-if="hasCard" :key="`${$route.fullPath}-${routerViewKey.state.value}`" />
    </keep-alive>
    <Loading :isLoading="loadingSpinner.state.isLoading || refreshing"/>
    <DialogWarning @on-close="dialogWarning.state.onClose" :showDialog="dialogWarning.state.showDialog" :message="dialogWarning.state.message" :detail="dialogWarning.state.detail"/>
    <Snackbar :showSnack="snackbar.state.showSnack" :message="snackbar.state.message" />
    <cookie-banner v-if="showCookieBanner" @on-accept="acceptCookies" />

    <!-- Modal: Update alert -->
    <v-dialog data-cy="app-update-dialog" v-model="updateExists" persistent width="378">
      <v-card>
        <v-card-title class="dialog-title primary" :style="{'height': '40px'}">
        </v-card-title>
        <div data-cy="app-update-dialog-content" class="dialog-content text-xl mt-6 mb-4">
          {{ $t('app.alertUpdate') }}
        </div>

        <v-card-actions class="dialog-actions justify-center px-4 pb-4">
          <v-btn
            data-cy="app-update-dialog-ok"
            width="120"
            depressed
            color="primary"
            class="btn-modal-ok"
            @click="refreshApp"
          >
            {{ $t('app.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import liff from '@line/liff'
import Loading from '@/components/Loading.vue'
import DialogWarning from '@/components/DialogWarning.vue'
import Snackbar from '@/components/Snackbar.vue'
import CookieBanner from '@/components/CookieBanner.vue'

export default {
  components: {
    Loading,
    DialogWarning,
    Snackbar,
    CookieBanner
  },
  data () {
    return {
      registration: null,
      updateExists: false,
      refreshing: false,
      hasCard: false,
      showCookieBanner: false
    }
  },
  beforeCreate () {
    // if (this.$route.name) this.initLiffApp()
  },
  created () {
    const self = this
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', self.updateAvailable, { once: true })

    // Prevent multiple refreshes
    if ('navigator' in window) {
      if ('serviceWorker' in window.navigator) {
        window.navigator.serviceWorker.addEventListener('controllerchange', () => {
          // We'll also need to add 'refreshing' to our data originally set to false.
          if (self.refreshing) {
            return
          }
          self.refreshing = true
          // Here the actual reload of the page occurs
          window.location.reload()
        })
      }
    }
  },
  mounted () {
    this.initPageHeight()
    this.preventZoom()
    if (this.$route.name) {
      this.initPage()
    }
    // this.$i18n.locale = 'en'
    // this.$vuetify.theme.dark = false
    // support app callback
    window.backCallback = this.backCallback
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.removeEventListener('touchmove', this.onTouchMove)
    }
    delete window.backCallback
  },
  methods: {
    initPage () {
      // add event
      if (this.$route.name) {
        this.$gtag.event('Page_enter', {
          event_category: 'Page Enter',
          event_label: this.$route.name,
          event_url: this.$route.fullPath
        })
      }
      this.setSiteLanguage()
      this.initLiffApp()
    },
    updateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        return
      }
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onResize (event) {
      let vh = liff.isInClient() ? '100%' : `${window.innerHeight}px`
      let vhIgnoreLine = `${window.innerHeight}px`
      const bottomOffset = getComputedStyle(document.documentElement).getPropertyValue('--sab')
      if (bottomOffset) {
        vh = `calc(${vh} - ${bottomOffset})`
        vhIgnoreLine = `calc(${vhIgnoreLine} - ${bottomOffset})`
      }
      document.documentElement.style.setProperty('--vh', vh)
      document.documentElement.style.setProperty('--vh-ignore-line', vhIgnoreLine)
    },
    onTouchMove (event) {
      if (event.scale !== 1) {
        event.preventDefault()
      }
    },
    initPageHeight () {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    },
    preventZoom () {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', this.onTouchMove, { passive: false })
      }
    },
    setSiteLanguage () {
      switch (liff.getLanguage().toLowerCase()) {
        case 'th':
        case 'th-th':
          this.$i18n.locale = 'th'
          break
        case 'ja':
        case 'ja-jp':
          this.$i18n.locale = 'jp'
          break
        default:
          this.$i18n.locale = 'en'
      }
    },
    acceptCookies () {
      this.$cookies.set('acceptCookies', true, '10y')
      this.showCookieBanner = false
    },
    initLiffApp () {
      const self = this
      const liffId = this.$route.query.liffId ? this.$route.query.liffId : localStorage.getItem('liffId')
      const ignoreLiff = this.$route.query.ignoreLiff
      if (liffId && !ignoreLiff) {
        // Clear local token when liffId change
        if (liffId !== localStorage.getItem('liffId')) {
          localStorage.removeItem('token')
        }
        liff.init({ liffId: liffId }).then(data => {
          localStorage.setItem('liffId', liffId)
          self.initState()
          self.initCard()
        }).catch((error) => {
          const hasCodeException = ['INVALID_ID_TOKEN', 'invalid_request'].includes(error.code)
          const hasMessageException = ['Load failed'].includes(error.message)
          if (!hasCodeException && !hasMessageException) {
            self.api.reportSOS({
              errorCode: error.code,
              errorMessage: error.message,
              fullPath: this.$route.fullPath.substring(0, 100)
            })
          }
          console.warn(error)
        })
      } else {
        self.initState()
        self.initCard()
      }
    },
    initState () {
      // check token in url (assuming token always comes with deviceId or uuid)
      const token = this.$route.query.token
      if (token) {
        // clear profile state if accessed from a different user
        if (token !== localStorage.getItem('token')) {
          this.sessionManager.resetUserState()
        }
        // init user state
        sessionStorage.setItem('isLoggedIn', true)
        localStorage.setItem('token', token)
        if (this.$route.query.deviceId) localStorage.setItem('deviceId', this.$route.query.deviceId)
        if (this.$route.query.uuid) sessionStorage.setItem('uuid', this.$route.query.uuid)
      }
      // check locale in url
      const locale = this.$route.query.locale
      if (locale) {
        this.$i18n.locale = (locale === 'ja') ? 'jp' : locale
      }
      // check header in url
      const header = this.$route.query.header
      if (header === '0') {
        sessionStorage.setItem('hideHeader', true)
      } else if (header === '1') {
        sessionStorage.removeItem('hideHeader')
      }
      // hide cookie banner if this page is opened from WebView
      if (!(this.utility.isMobileNativeApp() || (header === '0'))) {
        if (!this.$cookies.get('acceptCookies')) {
          this.showCookieBanner = true
        }
      }
    },
    initCard () {
      // use localStorage to skip getCardDetail
      // if (localStorage.getItem('card')) {
      //   sessionStorage.setItem('card', localStorage.getItem('card'))
      // }
      const card = sessionStorage.getItem('card') ? JSON.parse(sessionStorage.getItem('card')) : null
      const cardId = this.$route.params.cardId
      // Clear card data, card menu, and user profile when detect card id change
      if (!card || (parseInt(card.id) !== parseInt(cardId))) {
        this.sessionManager.resetCardState()
        this.getCardDetail(cardId)
      } else {
        this.initCardName()
        this.initTheme()
        this.hasCard = true
      }
    },
    initCardName () {
      const card = sessionStorage.getItem('card') ? JSON.parse(sessionStorage.getItem('card')) : {}
      window.document.title = card && card.name ? card.name : ''
    },
    initTheme () {
      const header = this.$route.query.header
      const cuid = this.$route.query.cuid
      if ((this.utility.isMobileNativeApp() || header !== '0') && cuid !== '0') {
        // dark mode listener
        const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        if (darkMediaQuery.addEventListener) {
          darkMediaQuery.addEventListener('change', (e) => {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          })
        }
      } else {
        this.$vuetify.theme.dark = false
      }
      // primary color
      const card = sessionStorage.getItem('card') ? JSON.parse(sessionStorage.getItem('card')) : {}
      if (card.light_color) this.$vuetify.theme.themes.light.primary = card.light_color
      if (card.dark_color) this.$vuetify.theme.themes.dark.primary = card.dark_color
    },
    // android callback
    backCallback (data) {
      if (data) history.back()
      else {
        if (['Card', 'ProfileEdit'].indexOf(this.$route.name) === -1) this.$router.replace({ name: 'Card', params: { cardId: this.$route.params.cardId } })
        else window.location = '/close'
      }
    },
    // ajax
    getCardDetail (cardId) {
      if (!cardId) return
      this.loadingSpinner.commit('show')
      this.api.getCardDetail({
        cardId,
        locale: this.$i18n.locale
      })
        .then(response => {
          let card
          switch (response.data.code) {
            case 200:
              card = response.data.data
              card.id = cardId
              sessionStorage.setItem('card', JSON.stringify(card))
              this.initCard()
              break
            default:
              if (response.data.msg) {
                this.dialogWarning.commit({
                  type: 'show',
                  message: response.data.msg,
                  detail: null
                })
              }
              break
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null
          })
        }).finally(() => {
          this.loadingSpinner.commit('hide')
        })
    }
  },
  watch: {
    $route: function (to, from) {
      // first entry
      if (!from.name) {
        this.initPage()
      }
      // close dialog if it's still active
      if (this.dialogWarning.state.showDialog) this.dialogWarning.commit('hide')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/main.scss';
@import '@/assets/style/pull-to.scss';
</style>
