<template>
  <div v-if="isLoggedIn" class="page">
    <div class="content-container card">
      <div class="cover-container">
        <v-img class="img-cover" aspect-ratio="2.88" :src="isProfileLoaded ? utility.getCoverImgSrc(this.$route.params.cardId, user.levelId ? user.levelId : '') : ''" @click="qrOverlayOpen = true">
          <template v-slot:placeholder>
            <div class="loader-dot"></div>
          </template>
        </v-img>
        <div v-if="VUE_APP_TYPE !== 'production'" class="cover-btn-container-top-left">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="rounded" elevation="3" fab x-small v-bind="attrs" v-on="on">
                {{ $i18n.locale }}
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item v-for="locale in ['th','en','jp']" :key="locale" @click="$i18n.locale = locale">
                <v-list-item-title>{{ locale.toUpperCase() }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="cover-btn-container">
          <v-btn id="btn-qr-scan" elevation="3" fab small @click="showMethodOptionDialog">
            <icon-base :width="20" :height="20" :icon-color="$vuetify.theme ? $vuetify.theme.currentTheme.primary : null" viewBox="0 0 20 17" icon-name="btn-show-qr">
              <icon-scan-qr></icon-scan-qr>
            </icon-base>
          </v-btn>
          <v-btn class="ml-2" elevation="3" fab small @click="showQrDialog">
            <icon-base :width="20" :height="20" :icon-color="$vuetify.theme ? $vuetify.theme.currentTheme.primary : null" viewBox="0 0 20 20" icon-name="btn-show-qr">
              <icon-show-qr></icon-show-qr>
            </icon-base>
          </v-btn>
        </div>
      </div>
      <div class="card-content">
        <CardBlock :loading="!isProfileLoaded" :card="card" :user="user" :token="token" :deviceId="deviceId" :uuid="uuid" />
        <ActionBlock :isProfileLoading="!isProfileLoaded" :card="card" :user="user" :logaApp="!!uuid" />
      </div>
    </div>
    <LogaFooter />
    <!-- QR code Dialog -->
    <user-qr-dialog
      :showDialog="qrDialogOpen"
      :token="token"
      :deviceId="deviceId"
      :uuid="uuid"
      :cardId="$route.params.cardId"
      @on-close="qrDialogOpen = false"
    />
    <!-- Code input Dialog -->
    <v-dialog v-model="methodDialogOpen" width="300">
      <v-card class="dialog-ticket">
          <v-card-title class="dialog-title primary text-lg">
            {{ $t('card.selectCodeInput') }}
          </v-card-title>
          <div class="dialog-content text-xl mt-4 mx-6">
            <v-radio-group v-model="methodOption" hide-details class="pt-0">
              <div v-for="o in methodOptionList" :key="o.id">
                <v-radio
                  :value="o.id"
                  class="mb-2"
                >
                  <template slot="label">
                    <span class="text--primary text-lg">{{ o.name }}</span>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>
          <v-card-actions class="dialog-actions d-block px-4 pb-4">
            <v-btn
              rounded
              depressed
              color="primary"
              class="btn-modal"
              @click="onMethodOptionConfirmClick"
            >
              {{ $t('app.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Ticket Dialog -->
    <v-dialog v-model="ticketDialogOpen" width="300">
      <v-card class="dialog-ticket">
          <v-card-title class="dialog-title primary text-lg">
            {{ $t('card.ticketQrCode') }}
          </v-card-title>
          <div class="dialog-content text-xl mt-4 mx-6">
            <v-text-field
              v-model="ticketNumber"
              dense single-line
              maxlength="16"
              :placeholder="$t('card.enterCode')"
              :rules="[v => isTicketNumberValid || $t('app.error.onlyTicketNumber')]"
            >
            </v-text-field>
          </div>
          <v-card-actions class="dialog-actions d-block px-4 pb-4">
            <v-btn
              v-if="!isTicketNumberValid"
              key="btn-1"
              rounded
              depressed
              color="primary"
              class="btn-modal"
              disabled
            >
              {{ $t('app.confirm') }}
            </v-btn>
            <v-btn
              v-else
              key="btn-2"
              rounded
              depressed
              color="primary"
              class="btn-modal"
              :loading="isCodeDialogSubmitting"
              @click="onTicketConfirmClick"
            >
              {{ $t('app.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Modal: Show confirm after scanning -->
    <qr-confirm-dialog
      :showDialog="isQrConfirmDialogOpen"
      :qrCode="qrCode"
      :logaToken="logaToken"
      :uuid="uuid || deviceId"
      @on-close="isQrConfirmDialogOpen = false"
      @on-confirm="onQrConfirm"
    />
    <!-- Modal: Show QR after success -->
    <qr-success-dialog :showDialog="isQrResultDialogOpen" :qrResult="qrResult" @on-close="isQrResultDialogOpen = false" />
    <!-- QR Reader -->
    <qr-reader v-if="qrScanOpen" @on-close="qrScanOpen = false" @on-decode="onQrReaderDecode" />
    <!-- QR code Overlay -->
    <user-qr-overlay :showOverlay="qrOverlayOpen" :token="token" :deviceId="deviceId" :uuid="uuid" :cardId="$route.params.cardId" @on-close="qrOverlayOpen = false" />
    <!-- shop delivery status popup -->
    <shop-delivery-status-popup
      v-if="card.shop_food"
      :token="token"
      :deviceId="deviceId"
      :uuid="uuid"
      :cardId="$route.params.cardId"
    />
  </div>
</template>

<script>
import liff from '@line/liff'
import LogaFooter from '@/components/LogaFooter.vue'

export default {
  name: 'Card',
  components: {
    LogaFooter,
    IconBase: () => import('@/components/IconBase.vue'),
    IconShowQr: () => import('@/components/icons/IconShowQr.vue'),
    IconScanQr: () => import('@/components/icons/IconScanQr.vue'),
    CardBlock: () => import('@/views/card-block/CardBlock.vue'),
    ActionBlock: () => import('@/views/card-block/ActionBlock.vue'),
    UserQrDialog: () => import('@/components/UserQrDialog.vue'),
    QrReader: () => import('@/components/QrReader.vue'),
    QrSuccessDialog: () => import('@/components/QrSuccessDialog.vue'),
    QrConfirmDialog: () => import('@/components/QrConfirmDialog.vue'),
    UserQrOverlay: () => import('@/components/UserQrOverlay.vue'),
    ShopDeliveryStatusPopup: () => import('@/views/shop/components/ShopDeliveryStatusPopup.vue')
  },
  data () {
    return {
      VUE_APP_TYPE: process.env.VUE_APP_TYPE,
      token: localStorage.getItem('token'),
      logaToken: sessionStorage.getItem('logaToken'),
      deviceId: sessionStorage.getItem('uuid') ? null : localStorage.getItem('deviceId'),
      uuid: sessionStorage.getItem('uuid'),
      card: sessionStorage.getItem('card') ? JSON.parse(sessionStorage.getItem('card')) : {},
      isLoggedIn: sessionStorage.getItem('isLoggedIn') === 'true',
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
      isProfileLoaded: !!localStorage.getItem('user'),
      qrDialogOpen: false,
      qrOverlayOpen: false,
      // qr options
      methodOptionList: [
        { id: 'qr', name: this.$t('card.inputQr') },
        { id: 'code', name: this.$t('card.ticketQrCode') }
      ],
      methodDialogOpen: false,
      methodOption: 'qr',
      // scan qr
      qrScanOpen: false,
      isQrConfirmDialogOpen: false,
      qrCode: null,
      isQrResultDialogOpen: false,
      qrResult: null,
      scannedCoupon: {},
      scannedReward: {},
      // ticket
      ticketNumber: null,
      ticketDialogOpen: false,
      isCodeDialogSubmitting: false,
      // for form submit to app
      formSubmitTime: null
    }
  },
  computed: {
    isTicketNumberValid: function () {
      return (/^\d+$/.test(this.ticketNumber)) && this.ticketNumber.length === 16
    }
  },
  watch: {
    qrDialogOpen (val) {
      if (!val) this.getProfile()
    }
  },
  mounted () {
    const self = this
    const liffId = this.$route.query.liffId || localStorage.getItem('liffId')
    // const pageRedirect = this.$route.query['liff.state'] ? `/card/${this.$route.params.cardId}${this.$route.query['liff.state']}` : null
    const loginRedirect = sessionStorage.getItem('loginRedirect')
    if (liffId) {
      liff.ready.then(() => {
        const pageRedirect = self.$route.query.line_redirect ? `/card/${self.$route.params.cardId}/${self.$route.query.line_redirect}` : null
        if (!self.isLoggedIn) {
          if (!liff.isLoggedIn() && !liff.isInClient()) {
            if (pageRedirect) sessionStorage.setItem('loginRedirect', pageRedirect)
            liff.login()
          } else {
            // skip login if there's already a token and deviceId
            if (self.token && !self.token.startsWith('temp-') && self.deviceId) {
              console.log('login skipped')
              sessionStorage.setItem('isLoggedIn', true)
              self.isLoggedIn = true
              self.init(loginRedirect || pageRedirect)
            } else {
              console.log('login not skipped')
              if (pageRedirect) sessionStorage.setItem('loginRedirect', pageRedirect)
              self.$router.replace({ name: 'LoginLine', params: { cardId: self.$route.params.cardId }, query: self.$route.query })
            }
          }
        } else {
          self.init(loginRedirect || pageRedirect)
        }
      }).catch((error) => {
        console.warn(error)
      })
    } else {
      this.init()
    }
  },
  methods: {
    init (pageRedirect) {
      sessionStorage.removeItem('loginRedirect')
      // console.log(pageRedirect)
      if (pageRedirect) {
        this.checkConsentBeforeRedirect(pageRedirect)
      } else {
        // load profile
        this.getProfile()
        if (!this.logaToken) {
          if (this.uuid) {
            this.logaToken = this.token
            sessionStorage.setItem('logaToken', this.token)
          } else this.getLogaToken()
        }
      }
    },
    showQrDialog () {
      this.qrDialogOpen = true
    },
    showMethodOptionDialog () {
      if (this.uuid) {
        window.location = '/scan_qr'
      } else {
        this.methodOption = this.methodOptionList[0].id // reset option
        this.methodDialogOpen = true
      }
    },
    scanQr () {
      const self = this
      if (liff.isInClient() && liff.scanCode) {
        liff.scanCode().then(result => {
          if (result.value && result.value !== 'null') self.processQRCodeResult(result.value)
        })
      } else {
        this.qrScanOpen = true
      }
    },
    onMethodOptionConfirmClick () {
      this.methodDialogOpen = false
      switch (this.methodOption) {
        case 'qr':
          this.scanQr()
          break
        case 'code':
          this.ticketNumber = null
          this.ticketDialogOpen = true
          break
      }
    },
    onTicketConfirmClick () {
      this.processQRCodeResult(this.ticketNumber, false, true)
    },
    processQRCodeResult (value, fromAndroid = false, fromDialog = false) {
      let code = value
      if (fromAndroid) {
        code = code.replaceAll('+', ' ')
        code = decodeURIComponent(code)
      }
      code = this.utility.getScannedCode(code)
      if (code) {
        if ((/^\d+$/.test(code)) && code.length === 16) code = `TK:${code}`
        // check card id to make sure if it's in the correct card
        if (code.startsWith('ldcd://UP:') || code.startsWith('ldcd://ybatqbpneq:UC:') || code.startsWith('ldcd://UC:')) {
          const derivedCardId = parseInt(code.split(':')[code.startsWith('ldcd://ybatqbpneq:UC:') ? 3 : 2].substring(1))
          if (derivedCardId !== parseInt(this.$route.params.cardId)) {
            this.dialogWarning.commit({
              type: 'show',
              message: this.$t('card.codeCardError'),
              detail: null
            })
          } else {
            this.showQrUseConfirmation(code)
          }
        } else {
          if (fromDialog) {
            this.useScannedCode(code, fromDialog)
          } else {
            this.showQrUseConfirmation(code)
          }
        }
      } else {
        this.dialogWarning.commit({
          type: 'show',
          message: this.$t('card.codeFormatError'),
          detail: null
        })
      }
    },
    onQrReaderDecode (value) {
      this.qrScanOpen = false
      this.processQRCodeResult(value)
    },
    showQrUseConfirmation (code) {
      this.qrCode = code
      this.isQrConfirmDialogOpen = true
    },
    onQrConfirm (remark) {
      this.useScannedCode(this.qrCode, false, remark)
    },
    showTicketSuccessMessage (point, pointCardId) {
      // get card name
      this.api.getCardDetail({
        cardId: pointCardId,
        locale: this.$i18n.locale
      })
        .then(response => {
          switch (response.data.code) {
            case 200:
              this.dialogWarning.commit({
                type: 'show',
                message: this.$t('card.youGotPoint', {
                  point,
                  suffix: this.utility.getPluralSuffix(point, this.$i18n.locale),
                  cardName: response.data.data.name
                }),
                detail: null
              })
              break
          }
        }).catch((error) => {
          console.warn(error)
        }).finally(() => {
        })
    },
    showCreditSuccessMessage (amount, creditName, cardId) {
      // get card name
      this.api.getCardDetail({
        cardId,
        locale: this.$i18n.locale
      })
        .then(response => {
          switch (response.data.code) {
            case 200:
              this.dialogWarning.commit({
                type: 'show',
                message: this.$t('card.youUsedCredit', {
                  amount,
                  creditName,
                  cardName: response.data.data.name
                }),
                detail: null
              })
              break
          }
        }).catch((error) => {
          console.warn(error)
        }).finally(() => {
        })
    },
    // redirect to app to generate and save receipt image
    saveReceiptImage (type) {
      this.formSubmitTime = '' + parseInt((new Date()).getTime() / 1000)
      if (typeof (Android) !== 'undefined') { // Android
        if (type === 'coupon') {
          // eslint-disable-next-line no-undef
          Android.saveCouponSlip(
            parseInt(this.formSubmitTime), this.scannedCoupon.detail,
            this.qrResult.code, this.scannedCoupon.coupon_id
          )
        } else {
          // eslint-disable-next-line no-undef
          Android.saveRewardSlip(
            parseInt(this.formSubmitTime), this.scannedReward.name, this.scannedReward.remark,
            this.qrResult.data.transaction_id, this.scannedReward.reward_id, Math.abs(parseInt(this.scannedReward.point_amount))
          )
        }
      } else if (window.webkit && window.webkit.messageHandlers) { // iOS
        if (type === 'coupon') {
          if (window.webkit.messageHandlers.saveCouponSlip) {
            window.webkit.messageHandlers.saveCouponSlip.postMessage({
              coupon_id: this.scannedCoupon.coupon_id,
              at: parseInt(this.formSubmitTime),
              coupon_detail: this.scannedCoupon.detail,
              transaction_id: this.qrResult.code
            })
          }
        } else {
          if (window.webkit.messageHandlers.saveRewardSlip) {
            window.webkit.messageHandlers.saveRewardSlip.postMessage({
              reward_id: this.scannedReward.reward_id,
              amount: Math.abs(parseInt(this.scannedReward.point_amount)),
              at: parseInt(this.formSubmitTime),
              reward_name: this.scannedReward.name,
              reward_remark: this.scannedReward.remark,
              transaction_id: this.qrResult.data.transaction_id
            })
          }
        }
      }
    },
    getClientInfo (lineDisplayName) {
      const ar = []
      if (liff.isInClient()) ar.push('line ' + liff.getLineVersion())
      else if (this.uuid) ar.push('app')
      else ar.push('browser')
      if (lineDisplayName) ar.push(lineDisplayName)
      ar.push(this.utility.getBrowserName())
      return ar.join(', ')
    },
    redirectToPage (loginRedirect) {
      this.$router.replace({ path: loginRedirect })
    },
    goToConsent () {
      const params = new URLSearchParams()
      params.append('token', this.token)
      if (this.uuid) params.append('uuid', this.uuid)
      else params.append('device_id', this.deviceId)
      params.append('card_id', this.$route.params.cardId)
      params.append('channel', 'L')
      params.append('client', this.getClientInfo())
      params.append('redirect', window.location.href)
      const url = this.api.apiBaseUrl + `privateweb/consent?${params.toString()}`
      window.location = url
    },
    onGetProfileErrorModalClose () {
      if (liff.isInClient()) {
        liff.closeWindow()
      } else {
        this.$router.go(-1)
      }
    },
    // ajax
    checkConsentBeforeRedirect (loginRedirect) {
      const self = this
      // this.loadingSpinner.commit('show')
      this.api.checkConsent({
        token: this.token,
        deviceId: this.deviceId,
        uuid: this.uuid,
        cardId: self.$route.params.cardId,
        channel: 'L', // L = LINE
        client: this.getClientInfo()
      })
        .then(response => {
          switch (response.data.code) {
            case 200:
              if (response.data.data === 'Y') {
                self.redirectToPage(loginRedirect)
              } else {
                self.goToConsent()
              }
              break
            case 252:
              if (this.uuid) {
                window.location = '/info'
              } else {
                this.$router.replace({ name: 'LoginLine', params: { cardId: this.$route.params.cardId } })
              }
              break
            default:
              if (response.data.msg) {
                this.dialogWarning.commit({
                  type: 'show',
                  message: response.data.msg,
                  detail: null,
                  onClose: this.onGetProfileErrorModalClose
                })
              }
              break
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null,
            onClose: this.onGetProfileErrorModalClose
          })
        }).finally(() => {
          // this.loadingSpinner.commit('hide')
        })
    },
    getLogaToken () {
      this.api.getLogaToken({ locale: this.$i18n.locale, token: this.token, deviceId: this.deviceId, cardId: this.$route.params.cardId })
        .then(response => {
          switch (response.data.code) {
            case 200:
              this.logaToken = response.data.data.logaToken
              sessionStorage.setItem('logaToken', response.data.data.logaToken)
              break
            case 252:
            default:
              if (response.data.msg) {
                this.dialogWarning.commit({
                  type: 'show',
                  message: response.data.msg,
                  detail: null,
                  onClose: (event) => window.location.reload()
                })
              }
              break
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null,
            onClose: (event) => window.location.reload()
          })
        }).finally(() => {
        })
    },
    getProfile () {
      // this.loadingSpinner.commit('show')
      this.api.getUserProfile({ locale: this.$i18n.locale, token: this.token, deviceId: this.deviceId, uuid: this.uuid, cardId: this.$route.params.cardId })
        .then(response => {
          switch (response.data.code) {
            case 200:
              if (response.data.data.consent !== 'Y') {
                this.goToConsent()
              } else if ((!this.uuid) && response.data.data.force_redirect2profile) {
                this.$router.replace({ name: 'ProfileEdit', params: { cardId: this.$route.params.cardId }, query: { new_member: 1 } })
              } else {
                this.user = response.data.data
                this.isProfileLoaded = true
                if (this.user) {
                  localStorage.setItem('user', JSON.stringify(this.user))
                }
              }
              break
            case 252:
              if (this.uuid) {
                window.location = '/info'
              } else {
                this.$router.replace({ name: 'LoginLine', params: { cardId: this.$route.params.cardId } })
              }
              break
            default:
              if (response.data.msg) {
                this.dialogWarning.commit({
                  type: 'show',
                  message: response.data.msg,
                  detail: null,
                  onClose: this.onGetProfileErrorModalClose
                })
              }
              break
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null,
            onClose: this.onGetProfileErrorModalClose
          })
        }).finally(() => {
          // this.loadingSpinner.commit('hide')
        })
    },
    useScannedCode (code, fromTicketDialog = false, remark = null) {
      if (fromTicketDialog) this.isCodeDialogSubmitting = true
      else this.loadingSpinner.commit('show')
      const codeData = { locale: this.$i18n.locale, logaToken: this.logaToken, uuid: this.uuid || this.deviceId, cardId: this.$route.params.cardId, code }
      if (remark) codeData.privateRemark = remark
      this.api.useScannedCode(codeData)
        .then(response => {
          if (response.data.code === 200 || response.data.result === 'Success') {
            if (fromTicketDialog) {
              this.ticketDialogOpen = false
            }
            this.getProfile()
            if (code.startsWith('TK:') && response.data.data && response.data.data.new && response.data.data.card_id) {
              // point ticket
              this.showTicketSuccessMessage(response.data.data.new, response.data.data.card_id)
            } else if (code.startsWith('ldcd://UD:')) {
              // use credit
              const codeArray = code.split(':')
              // amount, creditName, cardId
              this.showCreditSuccessMessage(codeArray[4], codeArray[5], parseInt(codeArray[2].substring(1)))
            } else if (code.startsWith('ldcd://ybatqbpneq:UC:') || code.startsWith('ldcd://UC:')) {
              // coupon
              const derivedCouponId = parseInt(code.split(':')[code.startsWith('ldcd://ybatqbpneq:UC:') ? 4 : 3])
              if (response.data.code) sessionStorage.setItem('couponTransactionId', response.data.code)
              this.$router.push({ name: 'CouponDetail', params: { cardId: this.$route.params.cardId, couponId: derivedCouponId } })
            } else if (code.startsWith('ldcd://UP:')) {
              // reward
              this.qrResult = response.data
              this.isQrResultDialogOpen = true
              this.getRewardReceipt(code)
            } else {
              // member ticket, etc.
              this.snackbar.commit({
                type: 'show',
                message: 'Success'
              })
            }
          } else {
            if (response.data.msg) {
              this.dialogWarning.commit({
                type: 'show',
                message: response.data.msg,
                detail: null
              })
            }
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null
          })
        }).finally(() => {
          if (fromTicketDialog) this.isCodeDialogSubmitting = false
          else this.loadingSpinner.commit('hide')
        })
    },
    // get reward detail to create receipt
    getRewardReceipt (code) {
      if (!this.uuid) return
      const codeArray = code.split(':')
      this.scannedReward = {
        reward_id: parseInt(codeArray[3]),
        point_amount: parseInt(codeArray[4]),
        name: codeArray[5],
        remark: codeArray[5]
      }
      this.saveReceiptImage('reward')
    },
    // get coupon detail to create receipt
    getCouponReceipt (couponId) {
      if (!this.uuid) return
      this.api.getCouponDetail({
        locale: this.$i18n.locale,
        logaToken: this.logaToken,
        uuid: this.uuid || this.deviceId,
        cardId: this.$route.params.cardId,
        couponId
      })
        .then(response => {
          switch (response.data.code) {
            case 200:
              response.data.data.coupon_id = couponId
              this.scannedCoupon = response.data.data
              this.saveReceiptImage('coupon')
              break
          }
        }).catch((error) => {
          console.warn(error)
        }).finally(() => {
        })
    }
  },
  // hooks
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // support app callback
      window.showQrDialog = vm.showQrDialog
      window.processQRCodeResult = vm.processQRCodeResult
    })
  },
  beforeRouteLeave (to, from, next) {
    // attempt to fix randomly missing token
    if (this.token) {
      localStorage.setItem('token', this.token)
      localStorage.setItem('deviceId', this.deviceId)
    }
    // attempt to fix randomly missing user
    if (this.user) {
      localStorage.setItem('user', JSON.stringify(this.user))
    }
    delete window.processQRCodeResult
    delete window.showQrDialog
    next()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/card.scss';
</style>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
