import axios from 'axios'
import qs from 'qs'
import router from '@/router/index.js'

export class Api {
  constructor () {
    axios.interceptors.response.use(function (response) {
      switch (response.data.code) {
        case 401:
        case 403:
          if ((router.currentRoute?.name !== 'MobileEdit') && (router.currentRoute?.name !== 'LoginLine') && router.currentRoute?.params?.cardId) {
            if (typeof (Android) !== 'undefined') {
              // eslint-disable-next-line no-undef
              Android.refreshClientWebView()
            } else if (window.webkit?.messageHandlers?.refreshClientWebView) {
              window.webkit.messageHandlers.refreshClientWebView.postMessage({})
            } else {
              router.replace({ name: 'LoginLine', params: { cardId: router.currentRoute.params.cardId } })
            }
          } else {
            return response
          }
          // return Promise.reject(response.data.msg)
      }
      return response
    }, function (error) {
      return Promise.reject(error)
    })
    this.axios = axios
    this.appBaseUrl = process.env.VUE_APP_BASE_URL
    this.apiBaseUrl = (sessionStorage.getItem('useAlphaApi') ? process.env.VUE_APP_API_ALPHA_URL : process.env.VUE_APP_API_URL)
    this.longdoApiKey = process.env.VUE_APP_LONGDO_MAP_API_KEY
    this.config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 5000
    }
  }

  async getCountryList () {
    const response = await this.axios.get(this.appBaseUrl + 'country_code.json')
    return response
  }

  // sos
  async reportSOS (params) {
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateapi/lineweb/sos',
      qs.stringify(params)
    )
    return response
  }

  // consent
  async checkConsent ({ token, uuid, deviceId, cardId, channel, client }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_check_consent',
      {
        params: {
          token,
          uuid,
          device_id: deviceId,
          card_id: cardId,
          channel,
          client
        }
      }
    )
    return response
  }

  // Start Card Region
  async getCardDetail ({ locale, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getCardDetail',
      {
        params: {
          card_id: cardId,
          locale
        }
      }
    )
    return response
  }

  async getCardMenu ({ locale, token, deviceId, uuid, cardId, logaapp = false }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getCardMenu',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          logaapp
        }
      }
    )
    return response
  }

  async login ({ locale, lineAccessToken, deviceId, cardId, channelId, logaapp = false }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/login',
      {
        params: {
          locale,
          line_access_token: lineAccessToken,
          device_id: deviceId,
          card_id: cardId,
          channel_id: channelId,
          logaapp
        }
      }
    )
    return response
  }

  async logout ({ locale, token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/logout',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async verify ({ locale, token, deviceId, cardId, mobile, otp, client, optionalPrivacy, captchaResponse, turnstileResponse }) {
    const params = {
      locale,
      token,
      device_id: deviceId,
      card_id: cardId,
      mobile,
      client,
      optional_privacy: optionalPrivacy,
      'recaptcha-response': captchaResponse,
      'cf-turnstile-response': turnstileResponse
    }
    if (otp) params.otp = otp
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/verify',
      {
        params: params
      }
    )
    return response
  }

  async getUserProfile ({ locale, token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getUserProfile',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      },
      this.config
    )
    return response
  }

  async getExpiringPointList ({ locale, token, deviceId, uuid, cardId, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getExpiringPointList',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          offset: (page - 1) * pageSize,
          limit: pageSize
        }
      },
      this.config
    )
    return response
  }

  async getLevelHistory ({ locale, token, deviceId, uuid, cardId, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getLevelHistory',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          offset: (page - 1) * pageSize,
          limit: pageSize
        }
      },
      this.config
    )
    return response
  }

  async getLogaToken ({ locale, token, deviceId, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getLogaToken',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          card_id: cardId
        }
      },
      this.config
    )
    return response
  }

  async useScannedCode ({ locale, logaToken, uuid, cardId, code, privateRemark }) {
    let pathUrl = 'clientjson/v2_use_code'
    const params = {
      locale,
      token: logaToken,
      uuid
    }
    if (code.startsWith('TK:')) {
      pathUrl = 'clientjson/v2_add_point_ticket'
      params.serial = code.substring(3)
    } else if (code.startsWith('MT:')) {
      pathUrl = 'clientjson/v2_subscribe_by_ticket'
      params.serial = code.substring(3)
      params.card_id = cardId
    } else if (code.startsWith('BP:')) {
      pathUrl = 'clientjson/v2_bind_plastic_card'
      params.card_id = cardId
      params.card_barcode = code.substring(3)
      params.profile = false
    } else if (code.startsWith('ldcd://UP:')) {
      pathUrl = 'clientjson/v2_use_point_product'
      const codeAr = code.substring(10).split(':')
      params.pp_id = codeAr[1]
      params.card_id = cardId
      params.private_remark = privateRemark
    } else if (code.startsWith('ldcd://')) {
      params.code = code.substring(7)
    } else {
      params.code = code
    }
    const response = await this.axios.get(
      this.apiBaseUrl + pathUrl,
      { params },
      this.config
    )
    return response
  }

  async updateProfile ({ locale, token, deviceId, uuid, cardId, fname, lname, gender, birthday }) {
    const bd = new Date(birthday)
    const data = {
      locale,
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      fname,
      lname,
      gender,
      bd_year: birthday ? bd.getFullYear() : '',
      bd_month: birthday ? bd.getMonth() + 1 : '',
      bd_date: birthday ? bd.getDate() : ''
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateapi/lineweb/updateProfile',
      qs.stringify(data)
    )
    return response
  }

  // Update Profile

  async updateMemberProfile ({ locale, token, deviceId, uuid, cardId, params }) {
    const data = {
      locale,
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      // ...params
      profile_name: Object.keys(params),
      profile_value: Object.values(params)
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateapi/lineweb/updateMemberProfile',
      qs.stringify(data)
    )
    return response
  }

  async updateMobile ({ locale, token, deviceId, uuid, cardId, mobile, dialCode, otp, captchaResponse, turnstileResponse }) {
    const params = {
      locale,
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      mobile,
      dial_code: dialCode,
      'recaptcha-response': captchaResponse,
      'cf-turnstile-response': turnstileResponse
    }
    if (otp) params.otp = otp
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/updateMobile',
      {
        params: params
      }
    )
    return response
  }

  // Activity

  async getUserActivities ({ locale, token, deviceId, uuid, cardId, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getUserActivities',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          offset: (page - 1) * pageSize,
          limit: pageSize
        }
      }
    )
    return response
  }

  async getUserCoupons ({ locale, token, deviceId, uuid, cardId, filter, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getUserCoupons',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          filter,
          offset: (page - 1) * pageSize,
          limit: pageSize
        }
      }
    )
    return response
  }

  async getCouponDetail ({ locale, logaToken, uuid, cardId, couponId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v3_get_coupon_detail',
      {
        params: {
          locale,
          token: logaToken,
          uuid,
          card_id: cardId,
          coupon_id: couponId
        }
      }
    )
    return response
  }

  async useCoupon ({ locale, logaToken, uuid, cardId, couponId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_use_coupon',
      {
        params: {
          locale,
          token: logaToken,
          uuid,
          card_id: cardId,
          coupon_id: couponId
        }
      }
    )
    return response
  }

  async getCardRewards ({ locale, token, deviceId, uuid, cardId, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/lineweb/getRewards',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          offset: (page - 1) * pageSize,
          limit: pageSize
        }
      }
    )
    return response
  }

  async redeemReward ({ locale, logaToken, uuid, cardId, rewardId, privateRemark }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_use_point_product',
      {
        params: {
          locale,
          token: logaToken,
          uuid,
          card_id: cardId,
          pp_id: rewardId,
          private_remark: privateRemark
        }
      }
    )
    return response
  }
  // End Card Region

  // Start Booking Region
  async getBookingActivities ({ locale, cardId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_bookable_activities',
      {
        params: {
          locale,
          card_id: cardId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getActivityGroup ({ token, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_activity_group',
      {
        params: {
          token,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getBookingActivityDetail ({ locale, cardId, activityId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_activity',
      {
        params: {
          locale,
          card_id: cardId,
          activity_id: activityId
        }
      }
    )
    return response
  }

  async getBookingBranches ({ locale, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_bookable_branches',
      {
        params: {
          locale,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getBookingBranchDetail ({ locale, cardId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/card/getBranch',
      {
        params: {
          locale,
          card_id: cardId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getBookableSlots ({ locale, cardId, activityId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_bookable_slots',
      {
        params: {
          locale,
          card_id: cardId,
          activity_id: activityId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getBookingAppointments ({ locale, token, deviceId, uuid, cardId, filter, status, offset }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_appointments',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          filter,
          status,
          offset
        }
      }
    )
    return response
  }

  async getBookingAppointmentDetail ({ locale, token, deviceId, uuid, cardId, appointmentId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/booking/get_appointment',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          appointment_id: appointmentId
        }
      }
    )
    return response
  }

  async createBookingAppointment ({
    token, deviceId, uuid, cardId, slotId, contact, remark, start, end, updateBy, paymentMethod, cuid, amount,
    omiseToken, returnUri, omiseCardId, remember
  }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      slot_id: slotId,
      contact,
      remark,
      start,
      end,
      update_by: updateBy,
      payment_method: paymentMethod,
      cuid,
      amount,
      omise_token: omiseToken,
      return_uri: returnUri,
      omise_card_id: omiseCardId,
      remember
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateapi/booking/create_appointment',
      qs.stringify(data)
    )
    return response
  }

  async updateBookingAppointment ({ token, deviceId, uuid, cardId, appointmentId, paymentMethod, omiseToken, returnUri, omiseCardId, remember }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      appointment_id: appointmentId,
      payment_method: paymentMethod + '',
      omise_token: omiseToken,
      return_uri: returnUri,
      omise_card_id: omiseCardId,
      remember
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateapi/booking/update_appointment',
      qs.stringify(data)
    )
    return response
  }

  async cancelBookingAppointment ({ token, deviceId, uuid, cardId, appointmentId }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      appointment_id: appointmentId
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateapi/booking/cancel_appointment',
      qs.stringify(data)
    )
    return response
  }

  async getBookingPaymentMethods ({ locale, token, uuid, deviceId, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_booking_paymentmethods',
      {
        params: {
          locale,
          token,
          uuid,
          device_id: deviceId,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getAppointmentQr ({ token, uuid, deviceId, cardId, appointmentId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_booking_generatepaymentqr',
      {
        params: {
          token,
          uuid,
          device_id: deviceId,
          card_id: cardId,
          appointment_id: appointmentId
        }
      }
    )
    return response
  }
  // End Booking Region

  // Start Shop Region
  async getBranchesWithDistance ({ locale, token, uuid, deviceId, cardId, shippingAddressId, lat, lon, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateapi/card/getBranchesWithDistance',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          shipping_address_id: shippingAddressId,
          lat,
          lon,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getProductCategories ({ locale, cardId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_productcategory',
      {
        params: {
          locale,
          card_id: cardId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getProducts ({ locale, cardId, branchId, keyword, category, hot, recommended, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_credit_products',
      {
        params: {
          locale,
          card_id: cardId,
          branch_id: branchId,
          keyword,
          category,
          hot,
          recommended,
          offset: page !== null && page !== undefined ? (page - 1) * pageSize : null
        }
      }
    )
    return response
  }

  async getProductDetail ({ locale, productId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_product',
      {
        params: {
          locale,
          product_id: productId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getOrders ({ locale, token, deviceId, uuid, cardId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getorders',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getDeliveryOrder ({ locale, token, deviceId, uuid, cardId, branchId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getdeliveryorder',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          branch_id: branchId
        }
      }
    )
    return response
  }

  async getOrderDetail ({ locale, token, uuid, deviceId, cardId, branchId, orderId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getorderdetail',
      {
        params: {
          locale,
          token,
          uuid,
          device_id: deviceId,
          card_id: cardId,
          branch_id: branchId,
          order_id: orderId,
          qr: false,
          timestamp: new Date().getTime()
        }
      }
    )
    return response
  }

  async getOrderQr ({ token, uuid, deviceId, cardId, orderId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_generatepaymentqr',
      {
        params: {
          token,
          uuid,
          device_id: deviceId,
          card_id: cardId,
          order_id: orderId
        }
      }
    )
    return response
  }

  async updateCart ({ token, deviceId, uuid, cardId, branchId, productId, optionId, lastOptionId, toppings, lastToppings, amount, note, time }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      branch_id: branchId,
      product_id: productId,
      option_id: optionId,
      last_option_id: lastOptionId,
      toppings: toppings instanceof Object ? JSON.stringify(toppings) : toppings,
      last_toppings: lastToppings instanceof Object ? JSON.stringify(lastToppings) : lastToppings,
      amount,
      note,
      time
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_shop_updatecart',
      qs.stringify(data)
    )
    return response
  }

  async updateOrder ({ token, deviceId, uuid, cardId, orderId, shippingMethod, paymentMethod }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      order_id: orderId,
      shipping_method: shippingMethod,
      payment_method: paymentMethod
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_shop_updateorder',
      qs.stringify(data)
    )
    return response
  }

  async verifyDiscountCode ({ locale, token, deviceId, uuid, cardId, orderId, discountCode }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_verifycode',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          order_id: orderId,
          code: discountCode
        }
      }
    )
    return response
  }

  async getPointDiscount ({ locale, token, deviceId, uuid, cardId, orderId, price }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getpointdiscount',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          order_id: orderId,
          price // ราคาสินค้า (ถ้าใช้ Code ลดราคาไปแล้วต้องหักออกจากตรงนี้ด้วย)
        }
      }
    )
    return response
  }

  async placeOrder ({
    locale, token, deviceId, uuid, cardId, note,
    shippingMethod, paymentMethod, discount, discountCode, shippingAddressId,
    omiseToken, orderId, returnUri, omiseCardId, remember
  }) {
    const data = {
      locale,
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      note,
      shipping_method: shippingMethod,
      payment_method: paymentMethod,
      discount, // กำหนดว่าจะลดด้วยแต้มหรือไม่ true /false
      discount_code: discountCode, // ใส่ Code ที่จะใช้ลดราคา Case-insensitive
      shipping_address_id: shippingAddressId,
      omise_token: omiseToken,
      order_id: orderId,
      return_uri: returnUri,
      omise_card_id: omiseCardId,
      remember,
      app_type: uuid ? 'app' : 'web'
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_shop_placeorder',
      qs.stringify(data)
    )
    return response
  }

  async reorder ({ token, deviceId, uuid, cardId, orderId }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      order_id: orderId
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_shop_reorder',
      qs.stringify(data)
    )
    return response
  }

  async cancelOrder ({ token, deviceId, uuid, cardId, orderId }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      order_id: orderId
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_shop_cancelorder',
      qs.stringify(data)
    )
    return response
  }

  async getShippingFee ({ token, deviceId, uuid, cardId, branchId, shippingMethod, shippingAddressId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getshippingfee',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          branch_id: branchId,
          shipping_method: shippingMethod,
          shipping_address_id: shippingAddressId
          // mock: 1
        }
      }
    )
    return response
  }
  // End Shop Region

  // Start Credit Card Region
  async getOmiseCards ({ locale, token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'payment/omiseCards',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async removeOmiseCard ({ locale, token, deviceId, uuid, cardId, omiseCardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'payment/removeOmiseCard',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          omise_card_id: omiseCardId
        }
      }
    )
    return response
  }

  async getGbCards ({ locale, token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'payment/gbCards',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async removeGbCard ({ locale, token, deviceId, uuid, cardId, gbToken }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'payment/removeGbCard',
      {
        params: {
          locale,
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          gb_token: gbToken
        }
      }
    )
    return response
  }
  // End Credit Card Region

  // Start Updates Region
  async getUpdates ({ token, deviceId, uuid, cardId, page, pageSize, pinned }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v3_get_feeds',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          offset: (page - 1) * pageSize,
          limit: pageSize,
          pinned
        }
      }
    )
    return response
  }

  async getPostComments ({ token, deviceId, uuid, cardId, postId, page, pageSize }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v3_get_post_comments',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          pid: postId,
          offset: (page - 1) * pageSize,
          limit: pageSize
        }
      }
    )
    return response
  }

  async updatePostComment ({ token, deviceId, uuid, cardId, commentId, action }) {
    const data = {
      token,
      card_id: cardId,
      cid: commentId,
      action
    }
    if (deviceId) data.device_id = deviceId
    if (uuid) data.uuid = uuid
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_update_post_comment',
      qs.stringify(data)
    )
    return response
  }

  async updateFeed ({ token, deviceId, uuid, cardId, feedId, action, comment, image }) {
    const formData = new FormData()
    formData.append('token', token)
    if (deviceId) formData.append('device_id', deviceId)
    if (uuid) formData.append('uuid', uuid)
    formData.append('card_id', cardId)
    formData.append('feed_id', feedId)
    formData.append('action', action)
    if (comment) formData.append('comment', comment)
    if (image) formData.append('image', image, image.name)
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_update_feed',
      formData
    )
    return response
  }
  // End Updates Region

  // Start Credit Region
  async getUserCredit ({ token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_credits',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          version: 1
        }
      }
    )
    return response
  }

  async getCreditUsage ({ token, deviceId, uuid, cardId, creditId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_credits_usage',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          cdid: creditId,
          version: 1
        }
      }
    )
    return response
  }

  async getCreditPrice ({ token, deviceId, uuid, cardId, creditId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v3_get_credits_price',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          cdid: creditId
        }
      }
    )
    return response
  }

  async createCreditOrder ({ token, deviceId, uuid, cardId, creditId, methodId, gateway, gbToken, rememberCard, creditNumber, expMonth, expYear, securityCode, creditCardName, responseUrl }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_create_credits_order',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          cdid: creditId,
          cdpid: methodId,
          app_type: uuid ? 'app' : 'web',
          gateway,
          gb_token: gbToken,
          remember_card: rememberCard,
          credit_number: creditNumber,
          exp_month: expMonth,
          exp_year: expYear,
          security_code: securityCode,
          credit_card_name: creditCardName,
          response_url: responseUrl
        }
      }
    )
    return response
  }

  async checkPaymentStatus ({ token, uuid, cardId, transactionId, gateway }) {
    const response = await this.axios.get(
      this.apiBaseUrl + `checkPaymentStatus/${cardId}/${transactionId}/${gateway}`,
      {
        params: {
          token,
          uuid
        }
      }
    )
    return response
  }

  // End Credit Region

  // Start Info Region
  async getCardBranches ({ locale, lon, lat, page, pageSize, tag, keyword }) {
    const response = await this.axios.get(
      'https://search.longdo.com/mapsearch/json/search',
      {
        params: {
          locale,
          lon,
          lat,
          offset: (page - 1) * pageSize,
          limit: pageSize,
          key: this.longdoApiKey,
          version: '2',
          tag,
          keyword
        }
      }
    )
    return response
  }

  async getPoiDetail ({ locale, ooiId }) {
    const response = await this.axios.get(
      'https://search.longdo.com/mapsearch/json/search',
      {
        params: {
          locale,
          // lon,
          // lat,
          offset: 0,
          limit: 1,
          key: this.longdoApiKey,
          version: '2',
          // tag,
          keyword: ooiId
        }
      }
    )
    return response
  }

  async getPoiImages ({ ooiId }) {
    const response = await this.axios.get(
      `https://promptpai.com/ws/promptpai/${ooiId}`
    )
    return response
  }

  async bindReferrerCode ({ token, deviceId, uuid, cardId, referrerCode }) {
    const data = {
      token,
      card_id: cardId,
      referrer_code: referrerCode
    }
    if (uuid) data.uuid = uuid
    else data.device_id = deviceId
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_bind_referrer_code',
      qs.stringify(data)
    )
    return response
  }

  async updateCard ({ token, deviceId, uuid, cardId, action }) {
    const data = {
      token,
      card_id: 'OL' + cardId,
      action // delete, pin, unpin, noti, nonoti, hide, unhide
    }
    if (uuid) data.uuid = uuid
    else data.device_id = deviceId
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_update_card',
      qs.stringify(data)
    )
    return response
  }
  // End Info Region

  // Start Longdo API Region
  async addressSuggest ({ keyword }) {
    const response = await this.axios.get(
      'https://search.longdo.com/mapsearch/json/suggest',
      {
        params: {
          keyword,
          limit: 4,
          // dataset: 'm2h_s,poi,poi2',
          dataset: 'poi,poi2,poi3,s_pg,m2h_s,poi_b,bus_s,s_osmpnt,s_osmline,s_osmpol',
          key: this.longdoApiKey
        }
      }
    )
    return response
  }

  async addressSearch ({ locale, keyword, lon, lat }) {
    const response = await this.axios.get(
      'https://search.longdo.com/mapsearch/json/search',
      {
        params: {
          locale,
          keyword,
          lon,
          lat,
          limit: 10,
          key: this.longdoApiKey
        }
      }
    )
    return response
  }

  async searchFromPostalCode ({ locale, postcode }) {
    const response = await this.axios.get(
      'https://api.longdo.com/POIService/json/address',
      {
        params: {
          locale,
          postcode,
          key: this.longdoApiKey
        }
      }
    )
    return response
  }

  async getAddressFromLatlon ({ locale, lat, lon }) {
    const response = await this.axios.get(
      'https://api.longdo.com/map/services/address',
      {
        params: {
          locale,
          lat,
          lon,
          key: this.longdoApiKey
        }
      }
    )
    return response
  }
  // End Longdo API Region

  // Start Address Region
  async getAddressList ({ token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getaddresslist',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getAddress ({ token, deviceId, uuid, cardId, addressId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_shop_getaddress',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          shipping_address_id: addressId // optional (return default address if omitted)
        }
      }
    )
    return response
  }

  async updateAddress ({ token, deviceId, uuid, cardId, addressId, fullname, address, subdistrict, district, province, geocode, countryCode, postalCode, lat, lon, tel, setAsDefault, status }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      shipping_address_id: addressId, // optional (omit to create new id)
      fullname,
      address,
      subdistrict,
      district,
      province,
      geocode,
      country_code: countryCode,
      postal_code: postalCode,
      lat,
      lon,
      tel,
      default: setAsDefault,
      status // A | I (set I to remove)
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_shop_updateaddress',
      qs.stringify(data)
    )
    return response
  }
  // End Address Region

  // Start Package (Subscription) Region
  async getSubscriptionConfig ({ token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_subscription_config',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getSubscriptionPackages ({ token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_subscription_packages',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getSubscriptionPackage ({ token, deviceId, uuid, cardId, packageId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_subscription_package',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          package_id: packageId
        }
      }
    )
    return response
  }

  async getSubscribedPackages ({ token, deviceId, uuid, cardId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_subscribed_packages',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId
        }
      }
    )
    return response
  }

  async getSubscribedPackage ({ token, deviceId, uuid, cardId, subscribeId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_subscribed_package',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          subscribe_id: subscribeId
        }
      }
    )
    return response
  }

  async getPackagePaymentHistory ({ token, deviceId, uuid, cardId, packageId }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'clientjson/v2_get_package_payment_history',
      {
        params: {
          token,
          device_id: deviceId,
          uuid,
          card_id: cardId,
          package_id: packageId
        }
      }
    )
    return response
  }

  async subscribePackage ({ token, deviceId, uuid, cardId, packageId, note, omiseToken, returnUri, omiseCardId, remember }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      package_id: packageId,
      note,
      omise_token: omiseToken,
      return_uri: returnUri,
      omise_card_id: omiseCardId,
      remember
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_subscribe_package',
      qs.stringify(data)
    )
    return response
  }

  async changeSubscriptionCard ({ token, deviceId, uuid, cardId, subscribeId, omiseToken, omiseCardId }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      subscribe_id: subscribeId,
      omise_token: omiseToken,
      omise_card_id: omiseCardId
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_change_subscription_card',
      qs.stringify(data)
    )
    return response
  }

  async cancelSubscriptionPackage ({ token, deviceId, uuid, cardId, subscribeId }) {
    const data = {
      token,
      device_id: deviceId,
      uuid,
      card_id: cardId,
      subscribe_id: subscribeId
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'clientjson/v2_cancel_subscription_package',
      qs.stringify(data)
    )
    return response
  }
  // End Package (Subscription) Region

  // Start Custom (JID) Region
  async getJIDTokens ({ token, uuid }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateweb/service/getJIDTokens',
      {
        params: {
          token,
          uuid
        }
      }
    )
    return response
  }

  async getJIDUser ({ token, uuid }) {
    const response = await this.axios.get(
      this.apiBaseUrl + 'privateweb/service/getJIDUser',
      {
        params: {
          token,
          uuid
        }
      }
    )
    return response
  }

  async requestJIDOTP ({ token, uuid, mobile }) {
    const data = {
      token,
      uuid
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateweb/service/requestJIDOTP',
      qs.stringify(data)
    )
    return response
  }

  async verifyJIDOTP ({ token, uuid, mobile, ref, otp }) {
    const data = {
      token,
      uuid,
      ref,
      otp
    }
    const response = await this.axios.post(
      this.apiBaseUrl + 'privateweb/service/verifyJIDOTP',
      qs.stringify(data)
    )
    return response
  }
  // End Custom (JID) Region
}
