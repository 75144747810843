<template>
  <div v-if="isLoading" data-cy="loading" id="loading">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/loading.scss';
</style>

<style lang="scss">
// loader-dot
.loader-dot {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 150% Impact;
  font-size: 42px;
  color: var(--v-primary-base, black);
  background-color: var(--v-background-secondary-base, #FFFFFF);
}
.loader-dot:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
  padding: 0 20px 20px 0;
}
@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: var(--v-primary-base, black);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 var(--v-primary-base, black),
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 var(--v-primary-base, black),
      .5em 0 0 var(--v-primary-base, black);}}
</style>
