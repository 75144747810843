/* eslint-disable no-console */

import { unregister } from 'register-service-worker'

if (process.env.NODE_ENV !== 'development') {
  unregister()
  // register(`${process.env.VUE_APP_BASE_URL}service-worker.js`, {
  //   ready () {
  //     console.log(
  //       'App is being served from cache by a service worker.\n' +
  //       'For more details, visit https://goo.gl/AFskqB'
  //     )
  //   },
  //   registered () {
  //     console.log('Service worker has been registered.')
  //   },
  //   cached () {
  //     console.log('Content has been cached for offline use.')
  //   },
  //   updatefound () {
  //     console.log('New content is downloading.')
  //   },
  //   updated (registration) {
  //     console.log('New content is available; please refresh.')
  //     if (caches) {
  //       caches.keys().then(function (names) {
  //         console.log(names)
  //         for (var name of names) caches.delete(name)
  //       })
  //     }
  //     document.dispatchEvent(
  //       new CustomEvent('swUpdated', { detail: registration })
  //     )
  //   },
  //   offline () {
  //     console.log('No internet connection found. App is running in offline mode.')
  //   },
  //   error (error) {
  //     console.error('Error during service worker registration:', error)
  //   }
  // })
}
