<template>
  <div class="page">
    <div class="verify">
      <div class="verify-content">
        <div class="content-container">
          <div class="text-header mt-4">{{$t('verify.code')}}</div>
          <div class="text--secondary">{{$t('verify.typeCode')}}</div>
          <div class="text--secondary">
            <span v-if="$i18n.locale !== 'jp'">{{$t('verify.typeCodeTo')}} </span>
            <span class="mobile">{{this.mobile}}</span>
            <span v-if="$i18n.locale === 'jp'"> {{$t('verify.typeCodeTo')}}</span>
          </div>
          <div class="digits">
            <v-card v-for="i in 4" class="digit" :key="i">
              {{ code.length > i-1 ? code.charAt(i-1):'' }}
            </v-card>
          </div>
          <input type="hidden" name="otp">
          <div class="text-error hidden">{{$t('verify.invalidCode')}}</div>
          <div v-if="!($route.name === 'VerifyPhone' && !turnstileToken) && (resendOtpTime <= 0)" class="resend-otp btn-link text--secondary" @click="resendOtpPrep">
            {{$t('verify.resendOtp')}}
          </div>
          <div v-else class="resend-otp btn-link disabled text--disabled">
            {{$t('verify.resendOtp')}}
          </div>
          <span v-if="resendOtpTime > 0" class="text--disabled"> ({{ resendOtpTime }})</span>
          <div class="d-flex justify-center mt-4">
            <div id="verify-turnstile" class="cf-turnstile" data-retry="never"></div>
          </div>
          <div class="digits-input">
            <div v-for="i in 3" :key="i">
              <v-btn icon class="digits-btn" v-for="j in 3" :key="j" @click="onNumberClick">
                {{ 3*(i-1)+j }}
              </v-btn>
            </div>
            <div>
              <v-btn icon class="digits-btn hidden"></v-btn>
              <v-btn icon class="digits-btn" @click="onNumberClick">0</v-btn>
              <v-btn icon class="digits-btn" @click="onBackspaceClick">
                <v-icon>mdi-backspace-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <LogaFooter />
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import liff from '@line/liff'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import LogaFooter from '@/components/LogaFooter.vue'

// Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY || '' })

export default {
  name: 'Verify',
  components: {
    LogaFooter
  },
  data () {
    return {
      token: localStorage.getItem('token'),
      deviceId: sessionStorage.getItem('uuid') ? null : localStorage.getItem('deviceId'),
      uuid: sessionStorage.getItem('uuid'),
      card: sessionStorage.getItem('card') ? JSON.parse(sessionStorage.getItem('card')) : {},
      code: '',
      mobile: this.$route.query.mobile,
      dialCode: this.$route.query.dial_code,
      resendOtpTime: localStorage.getItem('lastOtpTimestamp') ? this.getRemainingOtpTime(localStorage.getItem('lastOtpTimestamp')) : 0,
      resendOtpTimer: null,
      lineDisplayName: null,
      optionalPrivacy: sessionStorage.getItem('optionalPrivacy'),
      turnstileSiteKey: process.env.VUE_APP_TURNSTILE_SITE_KEY || '',
      turnstileToken: null
    }
  },
  mounted () {
    const self = this
    this.resendOtpTimer = setInterval(this.onOtpTimerTick, 1000)
    // get user's line display name
    liff.ready.then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile()
          .then(profile => {
            self.lineDisplayName = profile.displayName
          })
      }
    }).catch((error) => {
      console.warn(error)
    })
    // turnstile
    setTimeout(() => {
      self.initTurnstile()
    }, 500)
  },
  computed: {
    processedToken: function () {
      return this.token.startsWith('temp-') ? this.token.substring(5) : this.token
    }
  },
  methods: {
    initTurnstile () {
      const self = this
      if (self.$route.name === 'VerifyPhone') {
        window.turnstile?.ready(function () {
          window.turnstile.render('#verify-turnstile', {
            sitekey: self.turnstileSiteKey,
            action: 'client-editmobile-resend',
            callback: function (token) {
              self.turnstileToken = token
            },
            'error-callback': function () {
              self.turnstileToken = null
            }
          })
        })
      }
    },
    getRemainingOtpTime (timestamp) {
      return (120 - parseInt((new Date() - new Date(timestamp)) / 1000))
    },
    startOtpTimer () {
      localStorage.setItem('lastOtpTimestamp', new Date())
      this.resendOtpTime = 120
      this.resendOtpTimer = setInterval(this.onOtpTimerTick, 1000)
    },
    onOtpTimerTick () {
      if (this.resendOtpTime <= 1) {
        clearInterval(this.resendOtpTimer)
        this.resendOtpTime = 0
      } else {
        this.resendOtpTime--
      }
    },
    onNumberClick (e) {
      if (this.code.length < 4) this.code += e.target.innerText.trim()
    },
    onBackspaceClick (e) {
      if (this.code.length > 0) this.code = this.code.slice(0, -1)
    },
    getClientInfo () {
      const ar = []
      if (liff.isInClient()) ar.push('line ' + liff.getLineVersion())
      else ar.push('browser')
      if (this.lineDisplayName) ar.push(this.lineDisplayName)
      ar.push(this.utility.getBrowserName())
      return ar.join(', ')
    },
    async resendOtpPrep () {
      // await this.$recaptchaLoaded()
      // const token = await this.$recaptcha('login')
      const captchaToken = null
      this.resendOtp(captchaToken)
    },
    // ajax
    resendOtp (captchaResponse) {
      const self = this
      this.loadingSpinner.commit('show')
      let promise
      if (this.$route.name === 'VerifyPhone') {
        promise = this.api.updateMobile({
          locale: this.$i18n.locale,
          token: this.processedToken,
          deviceId: this.deviceId,
          uuid: this.uuid,
          cardId: this.$route.params.cardId,
          mobile: this.mobile,
          dialCode: this.dialCode,
          captchaResponse,
          turnstileResponse: this.turnstileToken
        })
      } else {
        promise = this.api.verify({
          locale: this.$i18n.locale,
          token: this.processedToken,
          deviceId: this.deviceId,
          cardId: this.$route.params.cardId,
          mobile: this.mobile,
          client: this.getClientInfo(),
          optionalPrivacy: this.optionalPrivacy,
          captchaResponse,
          turnstileResponse: this.turnstileToken
        })
      }
      // send data
      promise
        .then(response => {
          if (response.data.code === 200 && response.data.data.success) {
            const data = response.data.data
            if (this.$route.name === 'VerifyPhone') {
              this.startOtpTimer()
            } else {
              if (data.membership === 'yes') {
                if (data.verified === 'no') {
                  // set timer
                  this.startOtpTimer()
                } else {
                  // Already verified
                  this.token = this.processedToken // no longer temp
                  this.$router.replace({ name: 'Card', params: { cardId: this.$route.params.cardId } })
                }
              } else {
                if (self.card.self_subscribe) {
                  // set timer
                  this.startOtpTimer()
                } else {
                  // Not a member
                  this.$router.replace({ name: 'LoginLine', params: { cardId: this.$route.params.cardId } })
                }
              }
            }
          } else {
            this.dialogWarning.commit({
              type: 'show',
              message: (response.data.data && response.data.data.message) ? response.data.data.message : (response.data.msg ? response.data.msg : 'Unknown Error'),
              detail: null
            })
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null
          })
        }).finally(() => {
          this.loadingSpinner.commit('hide')
        })
    },
    verify () {
      const self = this
      this.loadingSpinner.commit('show')
      let promise
      if (this.$route.name === 'VerifyPhone') {
        promise = this.api.updateMobile({
          locale: this.$i18n.locale,
          token: this.processedToken,
          deviceId: this.deviceId,
          uuid: this.uuid,
          cardId: this.$route.params.cardId,
          mobile: this.mobile,
          dialCode: this.dialCode,
          otp: this.code
        })
      } else {
        promise = this.api.verify({
          locale: this.$i18n.locale,
          token: this.processedToken,
          deviceId: this.deviceId,
          cardId: this.$route.params.cardId,
          mobile: this.mobile,
          otp: this.code,
          client: this.getClientInfo(),
          optionalPrivacy: this.optionalPrivacy
        })
      }
      // send data
      promise
        .then(response => {
          let data
          switch (response.data.code) {
            case 200:
              data = response.data.data
              if (this.$route.name === 'VerifyPhone') {
                if (data.success) {
                  this.snackbar.commit({
                    type: 'show',
                    message: 'Success'
                  })
                  this.$router.go(-2)
                } else {
                  this.dialogWarning.commit({
                    type: 'show',
                    message: data.message,
                    detail: null
                  })
                }
              } else {
                if (data.membership === 'yes' && data.verified === 'yes') {
                  self.$gtag.event('Card_verified', {
                    event_category: 'Card',
                    event_label: self.card.name || ''
                  })
                  this.token = this.processedToken // no longer temp
                  this.$router.replace({ name: 'ProfileEdit', params: { cardId: this.$route.params.cardId }, query: { new_member: 1 } })
                } else {
                  this.dialogWarning.commit({
                    type: 'show',
                    message: data.message,
                    detail: null
                  })
                }
              }
              break
            case 469:
              this.dialogWarning.commit({
                type: 'show',
                message: this.$t('verify.invalidOtp'),
                detail: null
              })
              break
            default:
              this.dialogWarning.commit({
                type: 'show',
                message: response.data.msg,
                detail: null,
                onClose: (event) => {
                  self.$router.go(-1)
                }
              })
              break
          }
        }).catch((error) => {
          console.warn(error)
          this.dialogWarning.commit({
            type: 'show',
            message: 'Connection Error',
            detail: null
          })
        }).finally(() => {
          this.code = ''
          this.loadingSpinner.commit('hide')
        })
    }
  },
  watch: {
    code: function (val) {
      if (val.length === 4) this.verify()
    }
  },
  // hooks
  beforeRouteLeave (to, from, next) {
    // attempt to fix randomly missing token
    if (this.token) {
      localStorage.setItem('token', this.token)
      localStorage.setItem('deviceId', this.deviceId)
    }
    next()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/verify.scss';
@import url("https://fonts.googleapis.com/css?family=Material+Icons+Outlined&display=swap");
</style>
