import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    dark: darkMediaQuery.matches,
    themes: {
      light: {
        primary: '#3261FB',
        // primary: '#212121',

        // custom properties
        background: '#FFFFFF',
        'background-secondary': '#FFFFFF',
        loga: '#002774',
        'loga-secondary': '#f7642d'
      },
      dark: {
        // primary: '#3f51b5',

        // custom properties
        background: '#000000',
        'background-secondary': '#1e1e1e',
        loga: '#fff',
        'loga-secondary': '#f7642d'
      }
    },
    options: {
      customProperties: true
    }
  }
})
